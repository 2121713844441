import morerent from './assets/Cover.png'
import nexcent from './assets/Thumbnail.png'
import portfolio from './assets/portfolio.png'
import positivus from './assets/Positivus.png'
import beautifulsite from './assets/portfoliobeauty.png'
import Aahandyman from './assets/Aahandyman.png'

const foodData = [

  { 
    id: 1, 
    name: "Nexcent Landing Page", 
    category: "Business", 
    price: 0, 
    imageUrl: nexcent,
    liveSite: "https://nextcent-three.vercel.app/",
    new: 'New',
    github: 'https://github.com/winaung1/nextcent.git',
    productDescription: "This website has been made for youtube viewers and who is willing to learn how to do props and resuable components.",
    features: ["Tailwind CSS", "React JS", "1 Page", "Responsive"],
    childrenImgs: [
      "https://images.pexels.com/photos/14042707/pexels-photo-14042707.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/18849218/pexels-photo-18849218/free-photo-of-rings-on-newlyweds-hands.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/24861579/pexels-photo-24861579/free-photo-of-a-black-and-white-photo-of-a-woman-with-long-hair.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/22806869/pexels-photo-22806869/free-photo-of-a-street-light-with-a-pole-and-a-pole.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
    ]
  },
  { 
    id: 2, 
    name: "MORENT Car Rental", 
    category: "Car", 
    price: 0, 
    imageUrl: morerent,
    liveSite: "https://morerent-five.vercel.app/",
    productDescription: "This website has been made for youtube viewers and who is willing to learn how to do props and resuable components.",
    features: ["Tailwind CSS", "React JS", "1 Page", "Responsive"],
    github: 'https://github.com/winaung1/morerent.git',
    childrenImgs: [
      "https://images.pexels.com/photos/14042707/pexels-photo-14042707.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/18849218/pexels-photo-18849218/free-photo-of-rings-on-newlyweds-hands.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/24861579/pexels-photo-24861579/free-photo-of-a-black-and-white-photo-of-a-woman-with-long-hair.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/22806869/pexels-photo-22806869/free-photo-of-a-street-light-with-a-pole-and-a-pole.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
    ]
  },
  { 
    id: 3, 
    name: "React / Tailwind Porfolio", 
    category: "Portfolio", 
    price: 5, 
    imageUrl: portfolio,
    liveSite: "https://personalportfolio-psi-smoky.vercel.app/",
    productDescription: "This website has been made for youtube viewers and who is willing to learn how to do props and resuable components. This is a new porfolio for your future job.",
    features: ["Tailwind CSS", "React JS", "2 Pages", "Responsive", "Contact page", "Contact form", "React Icons"],
    github: 'https://github.com/winaung1/reactportfolio2024.git',
    childrenImgs: [
      "https://images.pexels.com/photos/14042707/pexels-photo-14042707.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/18849218/pexels-photo-18849218/free-photo-of-rings-on-newlyweds-hands.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/24861579/pexels-photo-24861579/free-photo-of-a-black-and-white-photo-of-a-woman-with-long-hair.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/22806869/pexels-photo-22806869/free-photo-of-a-street-light-with-a-pole-and-a-pole.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
    ]
  },
  { 
    id: 4, 
    name: "React / Tailwind Landing Page", 
    category: "Landing Page", 
    price: 5, 
    imageUrl: positivus,
    liveSite: "https://positivus-sage.vercel.app/",
    productDescription: "This is a beautiful landing page made for someone who would like to save time and jump start their business website. Great for all!",
    features: ["Tailwind CSS", "React JS", "1 Page", "Responsive", "Contact page", "Contact form", "React Icons", "swiper js"],
    github: 'https://github.com/winaung1/positivus.git',
    childrenImgs: [
      "https://images.pexels.com/photos/14042707/pexels-photo-14042707.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/18849218/pexels-photo-18849218/free-photo-of-rings-on-newlyweds-hands.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/24861579/pexels-photo-24861579/free-photo-of-a-black-and-white-photo-of-a-woman-with-long-hair.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/22806869/pexels-photo-22806869/free-photo-of-a-street-light-with-a-pole-and-a-pole.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
    ]
  },
  { 
    id: 5, 
    name: "React / Tailwind Full Portfolio Website", 
    category: "Portfolio", 
    price: 15, 
    imageUrl: beautifulsite,
    liveSite: "https://photography-xi-red.vercel.app/",
    productDescription: "This is a beautiful full website made for someone who would like to save time and jump start their website. Great for all! and all purpose",
    features: ["Tailwind CSS", "React JS", "7 Pages", "Responsive", "Contact page", "About page", "Project page", "Featured page", "Blog page", "Blog detail page", "Contact form", "React Icons", "custom swiper",],
    github: 'https://github.com/winaung1/photography.git',
    childrenImgs: [
      "https://images.pexels.com/photos/14042707/pexels-photo-14042707.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/18849218/pexels-photo-18849218/free-photo-of-rings-on-newlyweds-hands.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/24861579/pexels-photo-24861579/free-photo-of-a-black-and-white-photo-of-a-woman-with-long-hair.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/22806869/pexels-photo-22806869/free-photo-of-a-street-light-with-a-pole-and-a-pole.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
    ]
  },
  { 
    id: 6, 
    name: "Markly - Bags Shop Framer Template", 
    category: "Ecommerce", 
    price: 15, 
    imageUrl: 'https://images.ui8.net/uploads/6-ui-preview_1709465252460.png',
    liveSite: "https://generous-support-594136.framer.app/",
    productDescription: "Welcome to Markly, where style meets substance in our beautifully crafted bags. Explore our innovative website design that seamlessly blends elegance, functionality, and sustainability.",
    features: ["14+ Unique Framer pages",
      "Fully responsive",
      "No coding required",
      "Bootstrap Grid",
      "Well Organized",
      "Clean and Modern",],
    new: 'New',
    // github: 'https://github.com/winaung1/photography.git',
    childrenImgs: [
      "https://images.pexels.com/photos/14042707/pexels-photo-14042707.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/18849218/pexels-photo-18849218/free-photo-of-rings-on-newlyweds-hands.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/24861579/pexels-photo-24861579/free-photo-of-a-black-and-white-photo-of-a-woman-with-long-hair.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/22806869/pexels-photo-22806869/free-photo-of-a-street-light-with-a-pole-and-a-pole.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
    ]
  },
  { 
    id: 7, 
    name: "Flavoriz - Food Recipes Website Framer", 
    category: "Food", 
    price: 15, 
    imageUrl: 'https://images.ui8.net/uploads/01_preview_1719470066482.jpg',
    liveSite: "https://flavoriz.framer.website/",
    productDescription: "Serve your audiences and users with a comfortable website to explore and browse using Flavoriz, a clean and modern food recipes website Framer template. It has a refreshing vibe, especially from the combination of yellow accent color against the white background and black text. This look can invite more people to visit and even to try any recipe you offer. The clean and well-organized layout makes the website easier to explore. Use the template for your food recipes or cooking website.",
    features: ["Figma file included",
      "6 Pages",
      "CMS Support",
      "SEO Optimized",
      "Free Support",
      "Free Updates",
      ],
    new: 'New',
    // github: 'https://github.com/winaung1/photography.git',
    childrenImgs: [
      "https://images.pexels.com/photos/14042707/pexels-photo-14042707.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/18849218/pexels-photo-18849218/free-photo-of-rings-on-newlyweds-hands.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/24861579/pexels-photo-24861579/free-photo-of-a-black-and-white-photo-of-a-woman-with-long-hair.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/22806869/pexels-photo-22806869/free-photo-of-a-street-light-with-a-pole-and-a-pole.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
    ]
  },
  { 
    id: 8, 
    name: "Flavoriz - Food Recipes Website Framer", 
    category: "Food", 
    price: 55, 
    imageUrl: 'https://images.ui8.net/uploads/preview-1_1721272247868.png',
    liveSite: "https://ui8-core.herokuapp.com/index.html",
    productDescription: "Core is a clean and minimal dashboard UI Kit targeting a wide variety of use cases for desktop, tablet, and mobile applications in awesome light or dark mode 🔥",
    features: ["376 exclusive pre-built templates",
      "200+ components",
      "Global styleguide included",
      "Glossy light + dark mode",
      "Free google fonts",
      "Design + Code",
      "Figma",
      "HTML",
      "Bootstrap",
      "React"
      ],
    new: 'New',
    // github: 'https://github.com/winaung1/photography.git',
    childrenImgs: [
      "https://images.pexels.com/photos/14042707/pexels-photo-14042707.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/18849218/pexels-photo-18849218/free-photo-of-rings-on-newlyweds-hands.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/24861579/pexels-photo-24861579/free-photo-of-a-black-and-white-photo-of-a-woman-with-long-hair.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/22806869/pexels-photo-22806869/free-photo-of-a-street-light-with-a-pole-and-a-pole.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
    ]
  },
  { 
    id: 9, 
    name: "Aa Handyman Website", 
    category: "Construction", 
    price: 10, 
    imageUrl: Aahandyman,
    liveSite: "https://aahandyman.vercel.app/",
    productDescription: "Core is a clean and minimal dashboard UI Kit targeting a wide variety of use cases for desktop, tablet, and mobile applications in awesome light or dark mode 🔥",
    features: ["376 exclusive pre-built templates",
      "200+ components",
      "Global styleguide included",
      "Glossy light + dark mode",
      "Free google fonts",
      "Design + Code",
      "Figma",
      "HTML",
      "Bootstrap",
      "React"
      ],
    new: 'New',
    // github: 'https://github.com/winaung1/photography.git',
    childrenImgs: [
      "https://images.pexels.com/photos/14042707/pexels-photo-14042707.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/18849218/pexels-photo-18849218/free-photo-of-rings-on-newlyweds-hands.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      "https://images.pexels.com/photos/24861579/pexels-photo-24861579/free-photo-of-a-black-and-white-photo-of-a-woman-with-long-hair.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/22806869/pexels-photo-22806869/free-photo-of-a-street-light-with-a-pole-and-a-pole.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
    ]
  },
]

export default foodData;
